import React, { useState, useEffect } from 'react';
import "./Footer.scss";
import footerlogo from "../../assets/logo-footer.webp";

const Footer = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 1000 * 60 * 60); // Update every hour

    return () => clearInterval(intervalId);
  }, []);

  return (
    <footer>
      <div className="footer-container center">
        <div className="footer-container-logo">
          <img src={footerlogo} alt="logo" />
        </div>
        <div className="footer-container-text">
          <p>
            Buffalo King is a simple slot sponsored by Pragmatic Play. The slot
            has a 6 by 4 setup and 4096 ways to win. The Buffalo King max win
            stands at 93,750x. The slot has a lot more to offer, so here is a
            full review for more details.
          </p>
          <p>{currentYear} ©Buffalo King from Pragmatic Play. 18+</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
