import React from "react";
import "./Gvideo.scss";
import poster from "../../assets/maxresdefault.jpg";

const Gvideo = () => {
  return (
    <div className="center video">
      <div className="video-left">
        <h1 className="white-text">GAME DETAILS</h1>
        <table className="video-table">
          <tr>
            <td>Provider:</td>
            <td>Pragmatic Play</td>
          </tr>
          <tr>
            <td>Release Date:</td>
            <td>21.12.2019</td>
          </tr>
          <tr>
            <td>RTP:</td>
            <td>96,5%</td>
          </tr>
          <tr>
            <td>Layout:</td>
            <td>6 by 4</td>
          </tr>
          <tr>
            <td>Volatility:</td>
            <td>High</td>
          </tr>
          <tr>
            <td>Win Potential:</td>
            <td>93,750x</td>
          </tr>
          <tr>
            <td>Paylines:</td>
            <td>4096</td>
          </tr>
          <tr>
            <td>Bet Range:</td>
            <td>$0.40 - $60</td>
          </tr>
          <tr>
            <td>Devices:</td>
            <td>All Devices</td>
          </tr>
          <tr>
            <td>Rating:</td>
            <td>9.8/10</td>
          </tr>
          <tr>
            <td>Special features:</td>
            <td>Multipliers, free spins, scatters and wilds</td>
          </tr>
        </table>
      </div>
      <div className="video-right">
        <div className="video-right-content">
          <img className="video-link__img" src={poster} alt="" />
          <a href="https://catchthecatkz.com/d467ed7a5" target="_blank">
            <button className="button">PLAY NOW</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Gvideo;
