import React, { useState } from "react";
import "./Faq.scss";
import img1 from "../../assets/plus-solid.svg";
import img2 from "../../assets/xmark-solid.svg";

const Collapsible = ({ title, children }) => {
  const [isActive, setIsActive] = useState(false);

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  return (
    <div className="coll">
      <div>
        <button
          className={`collapsible ${isActive ? "active" : ""}`}
          onClick={handleToggle}
        >
          <div className="plus">
            {isActive ? (
              <i class="fa-solid fa-xmark" style={{ color: "#fff805" }}></i>
            ) : (
              <i class="fa-solid fa-plus"></i>
            )}
          </div>
          <div className="title">
            <p>{title}</p>
          </div>
        </button>
      </div>
      {isActive && (
        <div className="content">
          <p>{children}</p>
        </div>
      )}
    </div>
  );
};

const Faq = () => {
  return (
    <div className="faq">
      <div className="faq-container center">
        <h2>FAQS</h2>
        <Collapsible title="How does Buffalo King work?">
          Start spinning by selecting any amount between $0.40 and $60. Hit 3 or
          more similar symbols on any adjacent paylines to start playing.
        </Collapsible>
        <Collapsible title="How to play Buffalo King">
          Visit your favourite online casino and register to play for real
          money.
        </Collapsible>
        <Collapsible title="Where can I play the slot machine for free?">
          The slot is available on any registered and licensed online casino,
          including 22bet and Bizzo casino.{" "}
        </Collapsible>

        <div>
          <h2>BUFFALO KING REVIEW</h2>
          <p>
            Buffalo King slot machine is a simple slot set on a grid with 6
            reels, 4 horizontal rows and 4096 paylines, creating endless
            opportunities to create winning combinations. This is an excellent
            setup highly appreciated by players.
          </p>
          <p>
            The betting range starts from as low as $0.40 to a maximum of $60
            per spin. You can spin manually or by automating the spins. The
            autoplay option comes with super quick turbo spins.
          </p>
          <p>
            The online slot has 11 regular symbols, including 6 low pay and 5
            premiums, including royals A, K, Q, J, 10 and 9 as the least paying
            icons. You will also find animals but the buffalo is the most
            rewarding symbol in the game, paying up to 7.5x the stake.
          </p>
          <p>
            A win constitutes at least 3 matching symbols. Since the slot
            machine comes with 4096 paylines, wins start from the left to the
            right on any adjacent paylines. The canyon rock is the game's wild
            and only appears on reels 2-6 only.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Faq;
