import React from "react";
import "./Volatility.scss";
import Img1 from "../../assets/img1.jpg";
import Img2 from "../../assets/img2.png";
import Img3 from "../../assets/img3.jpg";
import Img4 from "../../assets/big-buffalo.webp";
import Img5 from "../../assets/buffalo-small.webp";

const Volatility = () => {
  return (
    <div className="center volatily">
      <h1 className="white-text-v">RTP AND VOLATILITY</h1>
      <p className="text-grey">
        Buffalo King has a super high volatility rating, meaning that wins are
        hard to come by but with an opportunity to hit huge wins. The return to
        the player rating is high at 96.5% above the market average.
      </p>
      <h1 className="yellow-text">SCREENSHOTS</h1>
      <div className="center row-row">
        <div className="row-column">
          <img className="img-row" src={Img1} alt="" />
        </div>
        <div className="row-column">
          <img className="img-row" src={Img2} alt="" />
        </div>
        <div className="row-column">
          <img className="img-row" src={Img3} alt="" />
        </div>
      </div>
      <div className="text-section">
        <img className="big-img" src={Img4} alt="" />
        <br />
        <h1 className="white-text-v">THEME, DESIGN AND MUSIC</h1>
        <p className="text-grey">
          The slot is set in the North American west wild, a common theme, but
          the Pragmatic Play has outdone itself. In the background of the reels,
          we can catch a glimpse of a starlit, blazing sky and a classic desert
          scene with rock protrusions and a variety of tumbleweeds. The buffalo
          is obviously the star of the game but is backed by various animals to
          complete the game's overall theme.
        </p>
        <p className="text-grey">
          The soundtrack also remains on theme with Dr Quinn's woman and festive
          bonanza alternating in different game stages. The sound effects are
          pretty rousing, with each spin accompanied by hooves of stampeding
          buffaloes. The music in the game is generally calm and
          non-distracting.
        </p>
        <h1 className="white-text-v">HOW TO PLAY BUFFALO KING SLOT</h1>
        <ul>
          <li>
            Click on "I" to view the rules and paytable to understand the game's
            requirements.
          </li>
          <li>
            Select the amount you want to bet using the +/- signs on the bottom
            right of the screen.
          </li>
          <li>
            Once you have selected the right amount, click on spin and watch the
            magic happen. There is also an option to automate the spins by
            setting the bankroll and the total number of spins.
          </li>
        </ul>
        <h1 className="white-text-v">BUFFALO KING BONUS FEATURES</h1>
        <p className="text-grey">
          The slot has maintained its simplicity by keeping the bonus features
          to the minimum. The in-game features include;
        </p>
        <br />
        <h2 className="yellow-text-s">WILDS</h2>
        <p className="text-grey">
          The game's wild symbol is the canyon rock that can substitute for any
          other regular paying symbols except the bonus symbol. It's the most
          important symbol as it connects with others in the game to achieve big
          wins.
        </p>
        <br />
        <p className="text-grey">
          The symbols also come with random wild multipliers between 2x, 3x or
          5x during free rounds. The value collected is then applied to your
          payout at the end of the free spin. If you land more than one wild,
          the multipliers are combined before applying to the payout.
        </p>
        <br />
        <h2 className="yellow-text-s">MULTIPLIERS</h2>
        <p className="text-grey">
          The game has a unique multiplier system that's more rewarding than
          most slots in the market. Players can walk away with up to 3125x in
          multipliers.
        </p>
        <br />
        <h2 className="yellow-text-s">BONUS</h2>
        <p className="text-grey">
          The Buffalo King bonus consists of two buffalo inspired symbols; the
          regular buffalo and the golden coin buffalo. The latter is the bonus
          symbol which is the only symbol that cannot be substituted by the
          wild.
        </p>
        <br />
        <h2 className="yellow-text-s">FREE SPINS BONUS</h2>
        <p className="text-grey">
          You will love Buffalo King free slot spins if you love a good deal.
          The free spins feature can be triggered by landing 3 or more scatters.
          Hitting 3-6 bonus scatters rewards 8, 15, 25 or 100 free spins.
          Retrigger the feature by landing at least 2 more scatters for 8-100
          additional spins.
        </p>
        <br />
        <p className="text-grey">
          In addition to the spins, any wild standing during the feature carries
          2x-5x multipliers. The values of all wilds hit are combined after the
          spin.
        </p>
        <br />
        <img className="small-buff" src={Img5} alt="" />
      </div>
      <h1 className="white-text-v">HOW TO WIN AT BUFFALO KING SLOT</h1>
      <p className="text-grey">
        Winning has been made super easy when playing Buffalo King, thanks to
        the 4096 paylines. All wins are calculated based on the reel positions
        and the number of reels. Multipliers collected are also applied to the
        payouts with up to 3x for every 6 of a kind.
      </p>
      <br />
      <p className="text-grey">
        A simple Buffalo King strategy we recommend is understanding the
        features of the video slot before playing. You can view the paytable and
        Buffalo King slot rules by clicking on the "I" on the bottom left of the
        screen.
      </p>
      <h1 className="white-text-v">PLAY BUFFALO KING FOR FREE</h1>
      <p className="text-grey">
        You can play for free by using the Buffalo King slot demo play that
        allows you to play without depositing any money or registering for an
        account. We recommend selecting the exact amount you would when playing
        for real. Also, ensure you spin severally to understand how many spins
        you need to achieve a win. Once you are familiar with the ropes of the
        game, you can go ahead and commit real money. The bottom line is you
        don't want to go into the wilderness without a few demo spins under your
        belt.
      </p>
      <h1 className="white-text-v">SLOT CONCLUSION</h1>
      <p className="text-grey">
        Buffalo King is a flawless slot with a user-friendly design making it
        super easy to play. The slot packs all the standard features you may
        need, plus some additional bonus elements. We love that the scatters
        needed to retrigger the free spins bonus has been lowered to make it
        super easy. The math model is well balanced, and the win potential is
        impressive. With over 4000 paylines, it's super easy to walk away with
        up to 93,750x in prizes. The graphics are also well done, with an
        enticing soundtrack and high-quality visuals.
      </p>
        <a href="https://catchthecatkz.com/d467ed7a5" target="_blank">
            <button className="button">PLAY NOW</button>
          </a>
    </div>
  );
};

export default Volatility;
