import React from "react";
import "./Playcard.scss";
import Catlogo from "../../assets/logo-cat.png";
import Pinuplogo from "../../assets/pinup1.webp";
import Gamalogo from "../../assets/gamalogo.png";

const Playcard = () => {
  return (
    <div className="center">
      <ul className="play-cards">
        <li className="play-card">
          <p className="raiting">9</p>
          <div className="card-img-warp">
            <img className="card-img" src={Catlogo} alt="" />
          </div>
          <p className="card-text">100% UP TO C$500 ON FIRST DEPOSIT</p>
          <a href="https://catchthecatkz.com/d467ed7a5" target="_blank">
            <button className="button">PLAY NOW</button>
          </a>
        </li>
        <li className="play-card play-card--large">
          <p className="raiting">10</p>
          <div className="card-img-warp">
            <img className="card-img" src={Pinuplogo} alt="" />
          </div>
          <p className="card-text">100% CASHBACK BONUS UP TO 1 BTC</p>
            <a href="https://onlinepuonline.com/cwbBKAX7/" target="_blank">
            <button className="button">PLAY NOW</button>
          </a>
        </li>
        <li className="play-card">
          <p className="raiting">9</p>
          <div className="card-img-warp">
            <img className="card-img" src={Gamalogo} alt="" />
          </div>
          <p className="card-text">C$1200 + 150 FREE SPINS WELCOME PACKAGE</p>
            <a href="https://colorful-road-three.com/d35db545f" target="_blank">
            <button className="button">PLAY NOW</button>
          </a>
        </li>
        
      </ul>
    </div>
  );
};

export default Playcard;
