import React from "react";
import "./Best.scss";
import Onewin from "../../assets/1win_lightmode.png";
import Catlogo from "../../assets/logo-cat.png";
import Pinuplogo from "../../assets/pinup1.webp";
import Gamalogo from "../../assets/gamalogo.png";
import Izzilogo from "../../assets/izzilog.png";
import Daddylogo from "../../assets/daddy.png";
import Vavadalogo from "../../assets/vavada.png";
import Kentlogo from "../../assets/kent.png";

const Best = () => {
  return (
    <div className="center">
      <h1 className="white-text">BEST CASINO TO PLAY</h1>
      <ul className="best-items">
        <li className="best-item">
          <div className="best-item_image">
            <img src={Onewin} alt="" />
          </div>
          <div className="best-item_center">
            <p className="raiting">10</p>
            <p className="bonus-text">100% CASHBACK BONUS UP TO 1 BTC</p>
          </div>
          <a href="https://1wauah.xyz/#79x5" target="_blank">
            <button className="button">PLAY NOW</button>
          </a>
        </li>
        <li className="best-item">
          <div className="best-item_image">
            <img src={Catlogo} alt="" />
          </div>
          <div className="best-item_center">
            <p className="raiting">10</p>
            <p className="bonus-text">100% CASHBACK BONUS UP TO 1 BTC</p>
          </div>
          <a href="https://catchthecatkz.com/d467ed7a5" target="_blank">
            <button className="button">PLAY NOW</button>
          </a>
        </li>
        <li className="best-item">
          <div className="best-item_image">
            <img src={Pinuplogo} alt="" />
          </div>
          <div className="best-item_center">
            <p className="raiting">10</p>
            <p className="bonus-text">100% CASHBACK BONUS UP TO 1 BTC</p>
          </div>
          <a href="https://onlinepuonline.com/cwbBKAX7/" target="_blank">
            <button className="button">PLAY NOW</button>
          </a>
        </li>
        <li className="best-item">
          <div className="best-item_image">
            <img src={Gamalogo} alt="" />
          </div>
          <div className="best-item_center">
            <p className="raiting">10</p>
            <p className="bonus-text">100% CASHBACK BONUS UP TO 1 BTC</p>
          </div>
          <a href="https://colorful-road-three.com/d35db545f" target="_blank">
            <button className="button">PLAY NOW</button>
          </a>
        </li>
        <li className="best-item">
          <div className="best-item_image">
            <img src={Izzilogo} alt="" />
          </div>
          <div className="best-item_center">
            <p className="raiting">10</p>
            <p className="bonus-text">100% CASHBACK BONUS UP TO 1 BTC</p>
          </div>
          <a href="https://izzicasino.life/cf91e09c3" target="_blank">
            <button className="button">PLAY NOW</button>
          </a>
        </li>
        <li className="best-item">
          <div className="best-item_image">
            <img src={Daddylogo} alt="" />
          </div>
          <div className="best-item_center">
            <p className="raiting">10</p>
            <p className="bonus-text">100% CASHBACK BONUS UP TO 1 BTC</p>
          </div>
          <a href="https://nice-road-two.com/d74076b1c" target="_blank">
            <button className="button">PLAY NOW</button>
          </a>
        </li>
        <li className="best-item">
          <div className="best-item_image">
            <img src={Vavadalogo} alt="" />
          </div>
          <div className="best-item_center">
            <p className="raiting">10</p>
            <p className="bonus-text">100% CASHBACK BONUS UP TO 1 BTC</p>
          </div>
          <a
            href="https://vavadapartners.pro/?promo=9e1e1391-392b-4e33-87c7-a3523df5e8cf&target=register"
            target="_blank"
          >
            <button className="button">PLAY NOW</button>
          </a>
        </li>
        <li className="best-item">
          <div className="best-item_image">
            <img src={Kentlogo} alt="" />
          </div>
          <div className="best-item_center">
            <p className="raiting">10</p>
            <p className="bonus-text">100% CASHBACK BONUS UP TO 1 BTC</p>
          </div>
          <a href="https://passage-through-trees.com/d753a0bcb" target="_blank">
            <button className="button">PLAY NOW</button>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Best;
