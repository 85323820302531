import React from "react";
import "./Offers.scss";
import Onewin from "../../assets/1win_lightmode.png";
import Catlogo from "../../assets/logo-cat.png";
import Pinuplogo from "../../assets/pinup1.webp";
import Gamalogo from "../../assets/gamalogo.png";
import Izzilogo from "../../assets/izzilog.png";
import Daddylogo from "../../assets/daddy.png";
import Vavadalogo from "../../assets/vavada.png";
import Kentlogo from "../../assets/kent.png";

const Offers = () => {
  return (
    <div className="center">
      <div className="offers-text">
        <h1>BEST OFFERS</h1>
      </div>
      <div>
        <ul className="offer-list">
          <li className="offer-item">
            <div className="offers-item-img">
              <img className="offers-img" src={Onewin} alt="" />
            </div>
            <div className="offer-item-center">
              <p className="raiting">10</p>
              <p className="offer-bonus">100% CASHBACK BONUS UP TO 1 BTC</p>
            </div>
            <a href=" https://1wauah.xyz/#79x5" target="_blank">
              <button className="button">PLAY NOW</button>
            </a>
          </li>
          <li className="offer-item">
            <div className="offers-item-img">
              <img className="offers-img" src={Pinuplogo} alt="" />
            </div>
            <div className="offer-item-center">
              <p className="raiting">10</p>
              <p className="offer-bonus">100% CASHBACK BONUS UP TO 1 BTC</p>
            </div>
            <a href="https://onlinepuonline.com/cwbBKAX7/" target="_blank">
              <button className="button">PLAY NOW</button>
            </a>
          </li>
          <li className="offer-item">
            <div className="offers-item-img">
              <img className="offers-img" src={Izzilogo} alt="" />
            </div>
            <div className="offer-item-center">
              <p className="raiting">10</p>
              <p className="offer-bonus">100% CASHBACK BONUS UP TO 1 BTC</p>
            </div>
            <a href="https://izzicasino.life/cf91e09c3" target="_blank">
              <button className="button">PLAY NOW</button>
            </a>
          </li>
          <li className="offer-item">
            <div className="offers-item-img">
              <img className="offers-img" src={Gamalogo} alt="" />
            </div>
            <div className="offer-item-center">
              <p className="raiting">10</p>
              <p className="offer-bonus">100% CASHBACK BONUS UP TO 1 BTC</p>
            </div>
            <a href="https://colorful-road-three.com/d35db545f" target="_blank">
              <button className="button">PLAY NOW</button>
            </a>
          </li>
          <li className="offer-item">
            <div className="offers-item-img">
              <img className="offers-img" src={Daddylogo} alt="" />
            </div>
            <div className="offer-item-center">
              <p className="raiting">10</p>
              <p className="offer-bonus">100% CASHBACK BONUS UP TO 1 BTC</p>
            </div>
            <a href="https://nice-road-two.com/d74076b1c" target="_blank">
              <button className="button">PLAY NOW</button>
            </a>
          </li>
          <li className="offer-item">
            <div className="offers-item-img">
              <img className="offers-img" src={Catlogo} alt="" />
            </div>
            <div className="offer-item-center">
              <p className="raiting">10</p>
              <p className="offer-bonus">100% CASHBACK BONUS UP TO 1 BTC</p>
            </div>
            <a href="https://catchthecatkz.com/d467ed7a5" target="_blank">
              <button className="button">PLAY NOW</button>
            </a>
          </li>
          <li className="offer-item">
            <div className="offers-item-img">
              <img className="offers-img" src={Vavadalogo} alt="" />
            </div>
            <div className="offer-item-center">
              <p className="raiting">10</p>
              <p className="offer-bonus">100% CASHBACK BONUS UP TO 1 BTC</p>
            </div>
            <a
              href="https://vavadapartners.pro/?promo=9e1e1391-392b-4e33-87c7-a3523df5e8cf&target=register"
              target="_blank"
            >
              <button className="button">PLAY NOW</button>
            </a>
          </li>
          <li className="offer-item">
            <div className="offers-item-img">
              <img className="offers-img" src={Kentlogo} alt="" />
            </div>
            <div className="offer-item-center">
              <p className="raiting">10</p>
              <p className="offer-bonus">100% CASHBACK BONUS UP TO 1 BTC</p>
            </div>
            <a
              href="https://passage-through-trees.com/d753a0bcb"
              target="_blank"
            >
              <button className="button">PLAY NOW</button>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Offers;
