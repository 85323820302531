import React from "react";
import "./Real.scss";
import Star from "../../assets/star.png";
import Buffalo from "../../assets/buffalo-king-1.jpg";

const Real = () => {
  return (
    <div className="center main-box">
      <div className="text-box">
        <div className="star-text">
          <img className="star" src={Star} alt="" />
          <p>Today the players won $ 79,540,652</p>
        </div>
        <h1 className="buffalo-king">BUFFALO KING SLOT</h1>
        <a href="https://catchthecatkz.com/d467ed7a5" target="_blank">
          <button className="button-72">PLAY FOR REAL MONEY</button>
        </a>
      </div>
      <div className="img-box">
        <img className="buffalo-img" src={Buffalo} alt="" />
      </div>
    </div>
  );
};

export default Real;
