import React from "react";
import "./Header.scss";
import logo from "../../assets/logo.webp";

const Header = () => {
  return (
    <header>
      <div className="header">
        <div className="header-container center">
          <div className="header-container-jackpot">
            <p>Jackpot</p>
            <p>C$ 964 000</p>
          </div>

          <div className="header-container-logo">
            <img src={logo} alt="logo" />
          </div>

          <div className="header-container-play">
            <a href="https://catchthecatkz.com/d467ed7a5" target="_blank">
              <button className="button">PLAY NOW</button>
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
